import http from "../http";
import axios from "axios";
/**
 * 数据字典服务接口
 */
/**
 * 请求类型
 */
export const getrequesttype = () => {
  let res = {}
  res.code = '0'
  res.data = [
    { name: "生产采购", index: "ProductionPurchase" },
    { name: "以销定采", index: "SalesPurchasing" },
    { name: "叫料采购", index: "CallPurchase" },
    { name: "订货采购-内贸大货", index: "OrderPurchase" },
    { name: "订货采购-内贸大样", index: "OrderBrokerBulkSamplePurchase" },
    { name: "原材料采购(成衣原料)", index: "RawMaterialPurchase" },
    { name: "内部往来订单", index: "InternalPurchase" },
    { name: "采购订单差异单", index: "DifferencePurchase" },
    { name: "大样", index: "Sample" },
    { name: "开发大样-零剪", index: "DevelopSample-Retail" },
    { name: "开发大样-职业装", index: "DevelopSampleSuits" },
    { name: "定织大样", index: "FixedWeaveSample" },
    { name: "船样", index: "ShipSample" },
    { name: "样包", index: "SampleBag" },
    { name: "新首-大货", index: "NewHeadBigGoods" },
    { name: "新首-大样", index: "NewHeadDetail" },
    { name: "日常备货-补单", index: "DailyStockSupplementOrder" },
    { name: "日常备货-含定织", index: "DailyStockFixedWeave" },
    { name: "待处理", index: "Pending" },
    { name: "特殊项目", index: "SpecialProjects" },
    { name: "预留询货", index: "ReservedInquiry" },
    { name: "配品", index: "Accessories" },
    { name: "原材料(坯布纱线)", index: "rawMaterial" },
    { name: "样本采购", index: "samplePurchase" }
  ]
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=PurchaseOrderType`
  // });
  return res
};



/**
* 仓位类型调取
*/
export const warehousequesttype = () => {
  let res = {}
  res.data = [
    { "name": "集团仓", "index": "A0" },
    { "name": "零剪仓", "index": "B0" },
    { "name": "职业装仓", "index": "C0" },
    { "name": "订货仓", "index": "D0" }
  ]
  res.code = '0'
  // return http({
  //     method: "get",
  //     url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=VirtualInventory`
  // });
  return res
};


/**
 * 预留类型
 */
export const getreservedtype = () => {
  let res = {}
  res.data = [
    {
      "id": 126,
      "rev": 1,
      "createTime": "2020-02-27T14:41:01.000+0000",
      "updateTime": "2020-02-27T14:41:04.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "A001",
      "dictItemName": "客订-面料",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 127,
      "rev": 1,
      "createTime": "2020-02-27T15:16:13.000+0000",
      "updateTime": "2020-02-27T15:16:16.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "A002",
      "dictItemName": "客订-样本样布",
      "dictItemOrder": 2,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 128,
      "rev": 1,
      "createTime": "2020-02-27T15:17:56.000+0000",
      "updateTime": "2020-02-27T15:17:58.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "A003",
      "dictItemName": "客订-画册样布",
      "dictItemOrder": 3,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 129,
      "rev": 1,
      "createTime": "2020-02-27T15:19:07.000+0000",
      "updateTime": "2020-02-27T15:19:09.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "A004",
      "dictItemName": "客订-样衣样布",
      "dictItemOrder": 4,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 130,
      "rev": 1,
      "createTime": "2020-02-27T15:19:49.000+0000",
      "updateTime": "2020-02-27T15:19:52.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "A005",
      "dictItemName": "客订-其他样布",
      "dictItemOrder": 5,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 131,
      "rev": 1,
      "createTime": "2020-02-27T15:20:35.000+0000",
      "updateTime": "2020-02-27T15:20:40.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "B006",
      "dictItemName": "非客订-面料",
      "dictItemOrder": 6,
      "remark": "123123123",
      "dictItemLanguage": "CH"
    },
    {
      "id": 132,
      "rev": 1,
      "createTime": "2020-02-27T15:21:46.000+0000",
      "updateTime": "2020-02-27T15:21:48.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "B007",
      "dictItemName": "非客订-画册样布",
      "dictItemOrder": 7,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 133,
      "rev": 1,
      "createTime": "2020-02-27T15:22:33.000+0000",
      "updateTime": "2020-02-27T15:22:44.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "B008",
      "dictItemName": "非客订-样本样布",
      "dictItemOrder": 8,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 134,
      "rev": 1,
      "createTime": "2020-02-27T15:23:31.000+0000",
      "updateTime": "2020-02-27T15:23:33.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "B009",
      "dictItemName": "非客订-其他样布",
      "dictItemOrder": 9,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 135,
      "rev": 1,
      "createTime": "2020-02-27T15:24:37.000+0000",
      "updateTime": "2020-02-27T15:24:39.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "B010",
      "dictItemName": "非客订-样衣样布",
      "dictItemOrder": 10,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 136,
      "rev": 1,
      "createTime": "2020-02-27T15:25:08.000+0000",
      "updateTime": "2020-02-27T15:25:10.000+0000",
      "dictIndex": "ReservedType",
      "dictItemValue": "C011",
      "dictItemName": "其他",
      "dictItemOrder": 11,
      "remark": null,
      "dictItemLanguage": "CH"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/dicts?dictIndex=ReservedType`
  // });
  return res
};



/**
 * 产品品类
 */
export const fabrictypeProductCategory = () => {
  let res = {}
  res.data = [

  ]
  res.code = '0'
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductCategory`
  });
};

/**
 * 产品季/产品特性
 */
export const fabrictypeProductSeason = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductSeason`
  });
};


/**
 * 采购计量单位
 */
export const fabrictypeSaleUnit = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=SaleUnit`
  });
};


/**
 * 物料类型
 */
export const fabrictypeMaterialType = () => {
  let res = {}
  res.data = [
    {
      "name": "其他",
      "index": "OtherTypes"
    },
    {
      "name": "面料",
      "index": "Fabric"
    },
    {
      "name": "坯布",
      "index": "GreyCloth"
    },
    {
      "name": "纱线",
      "index": "Yarn"
    },
    {
      "name": "毛衣纱线",
      "index": "WoollenYarnClothing"
    },
    {
      "name": "POLO衫面料",
      "index": "POLOFabric"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialType`
  // });
  return res
};


/**
 * 颜色
 */
export const fabrictypeProductcolor = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=Color`
  });
};



/**
 * 花型
 */
export const fabrictypeProductpattern = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=FlowerPattern`
  });
};

/**
 * 物料组
 */
export const fabrictypeProductmaterialGroup = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialCode`
  });
};

/**
 * 物料ABC分类
 */
export const fabrictypeMaterialABCClass = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialABCClass`
  });
};

/**
 * 品牌
 */
export const fabrictypeMaterialCode = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=Brand`
  });
};


/**
 * 物料系列
 */
export const fabrictypeMaterialSeries = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialSeries`
  });
};


/**
 * 物料属性
 */
export const fabrictypeMaterialproperties = () => {
  let res = {}
  res.data = [
    {
      "name": "新品",
      "index": "newProduct"
    },
    {
      "name": "常规",
      "index": "routine"
    },
    {
      "name": "清货",
      "index": "clearGoods"
    },
    {
      "name": "清补",
      "index": "clearanceReplenishment"
    },
    {
      "name": "清货延续",
      "index": "subsequentClearance"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=MaterialProperties`
  // });
  return res
};

/**
 * 物料分类
 */
export const fabrictypeMaterialClass = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialClass`
  });
};

/**
 * 组合物料分类
 */
export const fabricCompositeMaterial = () => {
  let res = {}
  res.data = [
    { "name": "样本", "index": "1" },
    { "name": "画册", "index": "2" }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=CombinedMaterialClass`
  // });
  return res
};

/**
 * 计量单位
 */
export const fabrictypeMaterialUnit = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialUnit`
  });
};

/**
 * 销售季
 */
export const fabrictypeSaleSeasou = () => {

  let res = {}
  res.data = [
    {
      "name": "S14", "index": "3"
    },
    { "name": "S15", "index": "4" },
    { "name": "S16", "index": "5" },
    { "name": "S17", "index": "6" },
    { "name": "S18", "index": "7" },
    { "name": "S19", "index": "1" },
    { "name": "S20", "index": "2" },
    { "name": "S21", "index": "18" },
    { "name": "S22", "index": "19" },
    { "name": "S23", "index": "21" },
    { "name": "S24", "index": "23" },
    { "name": "W10/11", "index": "8" },
    { "name": "W13/14", "index": "9" },
    { "name": "W14/15", "index": "10" },
    { "name": "W15/16", "index": "11" },
    { "name": "W16/17", "index": "12" },
    { "name": "W17/18", "index": "13" },
    { "name": "W18/19", "index": "14" },
    { "name": "W19/20", "index": "16" },
    { "name": "W20/21", "index": "15" },
    { "name": "W21/22", "index": "17" },
    { "name": "W22/23", "index": "20" },
    { "name": "W23/24", "index": "22" },
    { "name": "W24/25", "index": "24" }
  ]
  res.code = '0'
  // return http({
  //     method: "get",
  //     url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=SaleSeasou`
  //   });
  return res
};

//位置

export const fabrictypePosition = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=Position`
  });
};

/**
 * 物料状态
 */
export const materialStatusList = () => {
  let res = {}
  res.data = [
    {
      "name": "可售",
      "index": "1"
    },
    {
      "name": "不可售",
      "index": "2"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductStatus`
  // });
  return res
};

/**
 * 产品类型
 */
export const fabrictypeProductType = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductType`
  });
};

/**
 * 产品分类
 */
export const fabrictypeProductClass = () => {
  let res = {}
  res.data = [
    {
      "name": "常规-A",
      "index": "1"
    },
    {
      "name": "常规-B",
      "index": "2"
    },
    {
      "name": "常规-C",
      "index": "3"
    },
    {
      "name": "新品",
      "index": "4"
    },
    {
      "name": "清货",
      "index": "5"
    },
    {
      "name": "清货延续",
      "index": "6"
    },
    {
      "name": "清补",
      "index": "7"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductClass`
  // });
  return res
};

/**
 * 时尚度
 */
export const fabrictypeProductFashionDegree = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductFashionDegree`
  });
};

/**
 * 业务分类
 */
export const fabrictypebusiCategory = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=BusinessType`
  });
};


/**
 * 产品区分
 */
export const fabrictypeProductDistinguish = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=ProductDistinguish`
  });
};


/**
 * 付款状态
 */
export const paymentStatus = () => {
    let res = {}
    res.data =[
        {
            "name": "已付",
            "index": "AllPaid"
        },
        {
            "name": "待付",
            "index": "NoPaid"
        }
    ]
    res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=PaidStatus`
  // });
  return res
};
/**
 * 货妥记录--付款方式
 */
export const fabricPaymentMethod = () => {
  let res = {}
  res.data = [
    {
        "id": 160,
        "rev": 1,
        "createTime": "2020-05-25T18:49:20.420+0000",
        "updateTime": "2020-05-25T18:49:20.420+0000",
        "dictIndex": "paymentMethod",
        "dictItemValue": "downPayment",
        "dictItemName": "预付",
        "dictItemOrder": 1,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 161,
        "rev": 1,
        "createTime": "2020-05-25T18:49:48.997+0000",
        "updateTime": "2020-05-25T18:49:48.997+0000",
        "dictIndex": "paymentMethod",
        "dictItemValue": "period",
        "dictItemName": "账期",
        "dictItemOrder": 2,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 613,
        "rev": 1,
        "createTime": "2020-11-11T06:36:37.593+0000",
        "updateTime": "2020-11-11T06:36:37.593+0000",
        "dictIndex": "paymentMethod",
        "dictItemValue": "None",
        "dictItemName": "无",
        "dictItemOrder": 3,
        "remark": null,
        "dictItemLanguage": "CH"
    }
  ]
  res.code = '0'
  return res
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/dicts?dictIndex=paymentMethod`
  // });
};

/**
 * 尾款--付款状态
 */
export const finalPaymentMethod = () => {
  let res = {}
  res.data = [
    {
      "name": "已付",
      "index": "AllPaid"
    },
    {
      "name": "待付",
      "index": "NoPaid"
    },
    {
      "name": "付款中",
      "index": "PaidDuring"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=PaymentStatus`
  // });
  return res
};


/**
 * 节假日管理--节假日名称
 */
export const fabrictypeHoliday = () => {
  let res = {}
  res.data = [
    {
      "id": 177,
      "rev": 1,
      "createTime": "2020-06-08T19:23:24.879+0000",
      "updateTime": "2020-06-08T19:23:24.879+0000",
      "dictIndex": "holiday",
      "dictItemValue": "nationalDay",
      "dictItemName": "国庆节",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 178,
      "rev": 1,
      "createTime": "2020-06-08T19:26:54.718+0000",
      "updateTime": "2020-06-08T19:26:54.718+0000",
      "dictIndex": "holiday",
      "dictItemValue": "springFestival",
      "dictItemName": "春节",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 179,
      "rev": 1,
      "createTime": "2020-06-08T19:27:31.353+0000",
      "updateTime": "2020-06-08T19:27:31.353+0000",
      "dictIndex": "holiday",
      "dictItemValue": "summerVacation",
      "dictItemName": "暑假",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    },
    {
      "id": 180,
      "rev": 1,
      "createTime": "2020-06-08T19:27:57.257+0000",
      "updateTime": "2020-06-08T19:27:57.257+0000",
      "dictIndex": "holiday",
      "dictItemValue": "christmas",
      "dictItemName": "圣诞节",
      "dictItemOrder": 1,
      "remark": null,
      "dictItemLanguage": "CH"
    }
  ]
  res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dicts?dictIndex=holiday`
  // });
  return res
};


/**
 * 是否可供
 */
export const materialSupplyType = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dicts?dictIndex=MaterialSupplyType`
  });
};


/**
 * 主数据管理--字典提供
 */
export const fabrictypedictionaries = (index) => {
  if (index == 'VirtualInventory') {
    console.log('constDictList,VirtualInventory');
    let res = {}
    res.data = [
      { "name": "集团仓", "index": "A0" },
      { "name": "零剪仓", "index": "B0" },
      { "name": "职业装仓", "index": "C0" },
      { "name": "订货仓", "index": "D0" }
    ]
    res.code = '0'
    return res
  }
  if (index == 'Currency') {
    console.log('constDictList,Currency');
    let res = {}
    res.data = [
      { "name": "人民币", "index": "1" },
      { "name": "美元", "index": "2" },
      { "name": "欧元", "index": "3" },
      { "name": "英镑", "index": "4" },
      { "name": "日元", "index": "5" },
      { "name": "韩元", "index": "6" },
      { "name": "港币", "index": "7" }
    ]
    res.code = '0'
    return res
  }
  if (index == 'CombinedMaterialClass') {
    console.log('constDictList,CombinedMaterialClass');
    let res = {}
    res.data = [
      { "name": "样本", "index": "1" },
      { "name": "画册", "index": "2" }
    ]
    res.code = '0'
    return res
  }
  if (index == 'TransferType') {
    console.log('constDictList,TransferType');
    let res = {}
    res.data = [
      { "name": "客订-工厂调拨", "index": "A001" },
      { "name": "备货-工厂调拨", "index": "A002" }
    ]
    res.code = '0'
    return res
  }
  if (index == 'downPayment') {
    console.log('dictList,downPayment');
    let res = {}
    res.data = [
      {
        "id": 162,
        "rev": 1,
        "createTime": "2020-05-25T19:23:32.203+0000",
        "updateTime": "2020-05-25T19:23:32.203+0000",
        "dictIndex": "downPayment",
        "dictItemValue": "30%",
        "dictItemName": "30%",
        "dictItemOrder": 1,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 163,
        "rev": 1,
        "createTime": "2020-05-25T19:24:10.650+0000",
        "updateTime": "2020-05-25T19:24:10.650+0000",
        "dictIndex": "downPayment",
        "dictItemValue": "100%",
        "dictItemName": "100%",
        "dictItemOrder": 2,
        "remark": null,
        "dictItemLanguage": "CH"
      }
    ]
    res.code = '0'
    return res
  }
  if (index == 'ReservedType') {
    console.log('dictList,ReservedType');
    let res = {}
    res.data = [
      {
        "id": 126,
        "rev": 1,
        "createTime": "2020-02-27T14:41:01.000+0000",
        "updateTime": "2020-02-27T14:41:04.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "A001",
        "dictItemName": "客订-面料",
        "dictItemOrder": 1,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 127,
        "rev": 1,
        "createTime": "2020-02-27T15:16:13.000+0000",
        "updateTime": "2020-02-27T15:16:16.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "A002",
        "dictItemName": "客订-样本样布",
        "dictItemOrder": 2,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 128,
        "rev": 1,
        "createTime": "2020-02-27T15:17:56.000+0000",
        "updateTime": "2020-02-27T15:17:58.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "A003",
        "dictItemName": "客订-画册样布",
        "dictItemOrder": 3,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 129,
        "rev": 1,
        "createTime": "2020-02-27T15:19:07.000+0000",
        "updateTime": "2020-02-27T15:19:09.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "A004",
        "dictItemName": "客订-样衣样布",
        "dictItemOrder": 4,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 130,
        "rev": 1,
        "createTime": "2020-02-27T15:19:49.000+0000",
        "updateTime": "2020-02-27T15:19:52.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "A005",
        "dictItemName": "客订-其他样布",
        "dictItemOrder": 5,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 131,
        "rev": 1,
        "createTime": "2020-02-27T15:20:35.000+0000",
        "updateTime": "2020-02-27T15:20:40.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "B006",
        "dictItemName": "非客订-面料",
        "dictItemOrder": 6,
        "remark": "123123123",
        "dictItemLanguage": "CH"
      },
      {
        "id": 132,
        "rev": 1,
        "createTime": "2020-02-27T15:21:46.000+0000",
        "updateTime": "2020-02-27T15:21:48.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "B007",
        "dictItemName": "非客订-画册样布",
        "dictItemOrder": 7,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 133,
        "rev": 1,
        "createTime": "2020-02-27T15:22:33.000+0000",
        "updateTime": "2020-02-27T15:22:44.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "B008",
        "dictItemName": "非客订-样本样布",
        "dictItemOrder": 8,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 134,
        "rev": 1,
        "createTime": "2020-02-27T15:23:31.000+0000",
        "updateTime": "2020-02-27T15:23:33.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "B009",
        "dictItemName": "非客订-其他样布",
        "dictItemOrder": 9,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 135,
        "rev": 1,
        "createTime": "2020-02-27T15:24:37.000+0000",
        "updateTime": "2020-02-27T15:24:39.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "B010",
        "dictItemName": "非客订-样衣样布",
        "dictItemOrder": 10,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 136,
        "rev": 1,
        "createTime": "2020-02-27T15:25:08.000+0000",
        "updateTime": "2020-02-27T15:25:10.000+0000",
        "dictIndex": "ReservedType",
        "dictItemValue": "C011",
        "dictItemName": "其他",
        "dictItemOrder": 11,
        "remark": null,
        "dictItemLanguage": "CH"
      }
    ]
    res.code = '0'
    return res
  }
  if (index == 'purchaseType') {
    console.log('dictList,purchaseType');
    let res = {}
    res.data = [
      {
        "id": 623,
        "rev": 1,
        "createTime": "2021-01-13T03:26:59.147+0000",
        "updateTime": "2021-01-13T03:26:59.147+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B099999",
        "dictItemName": "世家宝-样本采购",
        "dictItemOrder": 1,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 147,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010201",
        "dictItemName": "以销定采-职业装",
        "dictItemOrder": 2,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 612,
        "rev": 1,
        "createTime": "2020-10-26T04:00:39.133+0000",
        "updateTime": "2020-10-26T04:00:39.133+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010206",
        "dictItemName": "以销定采-职业装-面料生产",
        "dictItemOrder": 3,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 149,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010401",
        "dictItemName": "以销定采-订货大样",
        "dictItemOrder": 4,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 140,
        "rev": 1,
        "createTime": "2020-10-19T03:42:17.000+0000",
        "updateTime": "2020-10-19T03:42:20.000+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010501",
        "dictItemName": "以销定采-订货大货",
        "dictItemOrder": 5,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 148,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010301",
        "dictItemName": "以销定采-零剪",
        "dictItemOrder": 6,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 171,
        "rev": 1,
        "createTime": "2020-05-31T23:43:34.614+0000",
        "updateTime": "2020-05-31T23:43:34.614+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010202",
        "dictItemName": "以销定采-零剪-叫料",
        "dictItemOrder": 7,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 611,
        "rev": 1,
        "createTime": "2020-10-26T03:59:33.314+0000",
        "updateTime": "2020-10-26T03:59:33.314+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010306",
        "dictItemName": "以销定采-零剪-面料生产",
        "dictItemOrder": 8,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 184,
        "rev": 1,
        "createTime": "2020-06-22T22:03:47.089+0000",
        "updateTime": "2020-06-22T22:03:47.089+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20305",
        "dictItemName": "备货采购-新首-职业装",
        "dictItemOrder": 9,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 185,
        "rev": 1,
        "createTime": "2020-06-22T22:04:27.451+0000",
        "updateTime": "2020-06-22T22:04:27.451+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20306",
        "dictItemName": "备货采购-新首补单-职业装",
        "dictItemOrder": 10,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 187,
        "rev": 1,
        "createTime": "2020-06-22T22:05:44.397+0000",
        "updateTime": "2020-06-22T22:05:44.397+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20308",
        "dictItemName": "备货采购-新首补单-零剪",
        "dictItemOrder": 11,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 186,
        "rev": 1,
        "createTime": "2020-06-22T22:05:05.803+0000",
        "updateTime": "2020-06-22T22:05:05.803+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20307",
        "dictItemName": "备货采购-新首-零剪",
        "dictItemOrder": 12,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 151,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020302",
        "dictItemName": "备货采购-职业装",
        "dictItemOrder": 13,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 153,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020203",
        "dictItemName": "备货采购-职业装-坯布/纱线",
        "dictItemOrder": 14,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 610,
        "rev": 1,
        "createTime": "2020-10-26T03:59:08.833+0000",
        "updateTime": "2020-10-26T03:59:08.833+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020206",
        "dictItemName": "备货采购-职业装-面料生产",
        "dictItemOrder": 15,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 150,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020301",
        "dictItemName": "备货采购-零剪",
        "dictItemOrder": 16,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 608,
        "rev": 1,
        "createTime": "2020-10-23T02:31:45.536+0000",
        "updateTime": "2020-10-23T02:31:45.536+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020304",
        "dictItemName": "备货采购-零剪-世家宝",
        "dictItemOrder": 17,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 154,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020303",
        "dictItemName": "备货采购-零剪-坯布/纱线",
        "dictItemOrder": 18,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 609,
        "rev": 1,
        "createTime": "2020-10-26T03:58:45.136+0000",
        "updateTime": "2020-10-26T03:58:45.136+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020306",
        "dictItemName": "备货采购-零剪-面料生产",
        "dictItemOrder": 19,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 637,
        "rev": 1,
        "createTime": "2021-02-01T10:42:11.309+0000",
        "updateTime": "2021-02-01T10:42:11.309+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020309",
        "dictItemName": "开发大样-零剪",
        "dictItemOrder": 20,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 644,
        "rev": 1,
        "createTime": "2021-10-29T06:57:46.888+0000",
        "updateTime": "2021-10-29T06:57:46.888+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020310",
        "dictItemName": "开发大样-职业装",
        "dictItemOrder": 21,
        "remark": null,
        "dictItemLanguage": "CH"
      },
      {
        "id": 197,
        "rev": 1,
        "createTime": "2020-10-13T02:28:40.000+0000",
        "updateTime": "2020-10-13T02:28:47.000+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020305",
        "dictItemName": "日常备货-集团",
        "dictItemOrder": 22,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 155,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B07",
        "dictItemName": "现货采购-原材料",
        "dictItemOrder": 23,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
      },
      {
        "id": 201,
        "rev": 1,
        "createTime": "2020-08-18T04:29:52.942+0000",
        "updateTime": "2020-08-18T04:29:52.942+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B9901",
        "dictItemName": "采购申请差异单",
        "dictItemOrder": 24,
        "remark": null,
        "dictItemLanguage": "CH"
      }
    ]
    res.code = '0'
    return res
  }
  if (index == 'SaleSeasou') {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" }
    ]
    res.code = '0'
    return res
  }
  if (index == 'MaterialFinishedClass') {
    console.log('MaterialFinishedClass');
    let res = {}
    res.data =  [
      { "name": "样衣",  "index": "12"},
      { "name": "销售配品(织标)",  "index": "17"},
      { "name": "销售配品(其他)",  "index": "18"},
      { "name": "领带",  "index": "13"},
      { "name": "毛衣",  "index": "14"},
      { "name": "围巾",  "index": "15"},
      { "name": "POLO",  "index": "8"},
      { "name": "圆T",  "index": "16"},
      { "name": "短款外套",  "index": "9"},
      { "name": "长款外套",  "index": "10"},
      { "name": "填充外套",  "index": "11"},
      { "name": "裤子",  "index": "6"},
      { "name": "其他",  "index": "99"},
      { "name": "套装",  "index": "1"},
      { "name": "单件",  "index": "2"},
      { "name": "大衣",  "index": "3"},
      { "name": "衬衫",  "index": "4"},
      { "name": "女装",  "index": "5"},
      { "name": "礼服",  "index": "7"},
      { "name": "样本",  "index": "19"},
      { "name": "画册",  "index": "20"},
      { "name": "织标",  "index": "21"}
  ]
    res.code = '0'
    return res
  }
  if (index == 'FactorySeason') {
    console.log('FactorySeason');
    let res = {}
    res.data = [
      { "name": "S19",  "index": "S19"},
      { "name": "W19/20",  "index": "W19/20"},
      { "name": "W22/23",  "index": "W22/23"},

    ]
    res.code = '0'
    return res
  }
  if (index == 'ProductClassifcationType') {
    console.log('ProductClassifcationType');
    let res = {}
    res.data = [
      { "name": "费用", "index": 1 },
      { "name": "电子画册",  "index": 2},
    ]
    res.code = '0'
    return res
  }
  if (index == 'ConfirmStatus') {
    console.log('ConfirmStatus');
    let res = {}
    res.data = [
      {
          "name": "待确认",
          "index": "NotReady"
      },
      {
          "name": "部分确认",
          "index": "PartiallyReady"
      },
      {
          "name": "已确认",
          "index": "AllReady"
      }
  ]
    res.code = '0'
    return res
  }
  if (index == 'ReserveReleaseStatus') {
    console.log('ReserveReleaseStatus');
    let res = {}
    res.data = [
      {
          "name": "解除",
          "index": "Release"
      },
      {
          "name": "预留",
          "index": "Reserve"
      }
  ]
    res.code = '0'
    return res
  }
  if (index == 'RetailAndSuitsMaterialProperties') {
    console.log('RetailAndSuitsMaterialProperties');
    let res = {}
    res.data = []
    res.code = '0'
    return res
  }
  if (index == 'CustomerStatus') {
    console.log('CustomerStatus');
    let res = {}
    res.data = [
      {   "name": "正常", "index": 1 },
      {   "name": "冻结", "index": 2 },
      {   "name": "注销", "index": 3 },
      {   "name": "禁用", "index": 4 },
      {   "name": "活跃", "index": 5 },
      { "name": "其他", "index": 6 },
    ]
    res.code = '0'
    return res
  }
  //ReserveReleaseStatus
  //ConfirmStatus
  //CustomerStatus
  // RetailAndSuitsMaterialProperties
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=${index}`
  });
};

/**
 * 主数据管理--字典提供
 */
export const fabrictypedictionariesUncons = (index) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dicts?dictIndex=${index}`
  });
};
/**
 * 物料编号更新
 */
export const getproMaterialCode = (index) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `materialUpdate/v1/queryByMaterialCode?materialCode=${encodeURIComponent(index)}`
  });
};
/**
 *批量物料编号更新
 */
export const saveproMaterialCode = (oldMaterialCode, newMaterialCode) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `materialUpdate/v1/updateMateiralCode?oldMaterialCode=${encodeURIComponent(oldMaterialCode)}&newMaterialCode=${encodeURIComponent(newMaterialCode)}`,

  });
};
/**
 * 产品编号更新
 */
export const getproProductCode = (index) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `productUpdate/v1/queryByProductCode?productCode=${index}`
  });
};
/**
 *批量产品编号更新
 */
export const saveproProductCode = (oldProductCode, newProductCode, productId) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `productUpdate/v1/updateProductCode?oldProductCode=${oldProductCode}&newProductCode=${newProductCode}&productId=${productId}`,

  });
};